import { Injectable } from '@angular/core';
import { AuthenticationResult, Configuration, PublicClientApplication } from '@azure/msal-browser';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable, from, of, switchMap } from 'rxjs';
import { USERS_ENDPOINT } from '../../../shared/constants';
import { UserBasicInfo } from '../models/UserBasicInfo';
import { ApiService } from '../../../shared/services';

@Injectable()
export class AuthService {
  private pca: PublicClientApplication;
  //TODO: Maybe another type will be used in the future, so this will most probably be changed
  private userSubject: BehaviorSubject<UserBasicInfo | null>;
  private usersApiBaseUrl = USERS_ENDPOINT;

  constructor(private router: Router, private apiService: ApiService) {
    const msalConfig: Configuration = {
      auth: {
        clientId: environment.msalConfig.auth.clientId,
        authority: environment.msalConfig.auth.authority,
        redirectUri: environment.msalConfig.auth.redirectUri,
      },
      cache: {
        cacheLocation: environment.msalConfig.cache.cacheLocation,
      },
    };
    this.pca = new PublicClientApplication(msalConfig);
    this.pca.initialize();

    this.userSubject = new BehaviorSubject<UserBasicInfo | null>(JSON.parse(localStorage.getItem('user')!));
  }

  get authenticatedUser(): UserBasicInfo | null {
    return this.userSubject.value;
  }

  login(): Observable<UserBasicInfo> {
    return from(this.pca.loginPopup({ scopes: environment.msalConfig.apiConfig.scopes })).pipe(
      switchMap((result: AuthenticationResult) => {
        localStorage.setItem('token', result.accessToken);
        return this._getAuthenticatedUserBasicInfo().pipe(
          switchMap((user: UserBasicInfo) => {
            this.userSubject.next(user);
            localStorage.setItem('user', JSON.stringify(user));
            this.router.navigate(['/dashboard']);
            return of(user);
          }),
        );
      }),
    );
  }

  logout(): Observable<void> {
    return from(this.pca.logoutPopup()).pipe(
      switchMap((_) => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        this.router.navigate(['/login']);
        return of();
      }),
    );
  }

  private _getAuthenticatedUserBasicInfo(): Observable<UserBasicInfo> {
    return this.apiService.get(this.usersApiBaseUrl);
  }
}
